var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section" }, [
      _vm.service
        ? _c(
            "div",
            { staticClass: "container container--sm h-textCenter h-mb-80" },
            [
              _c(
                "div",
                { staticClass: "iconWrapper" },
                [
                  _c("icon", {
                    staticClass: "serviceIcon",
                    attrs: { name: _vm.service.icon },
                  }),
                ],
                1
              ),
              _c("h2", { staticClass: "h2" }, [
                _vm._v(_vm._s(_vm.service.name)),
              ]),
              _c("p", { staticClass: "p" }, [
                _c("span", { staticStyle: { opacity: "0.6" } }, [
                  _vm._v("Service by "),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.producer.display_name))]),
              ]),
              _c("hr", { staticClass: "dividerCustom" }),
              _c("p", {
                staticClass: "p",
                domProps: { innerHTML: _vm._s(_vm.service.description) },
              }),
              _c(
                "router-link",
                {
                  staticClass: "btn btn--secondary",
                  attrs: { to: { name: "Home", hash: "#contact" } },
                },
                [_vm._v("Contact")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }